import React from "react"
import { Helmet } from "react-helmet"

const scripts = {
  googleAnalytics: {
    external: `https://www.googletagmanager.com/gtag/js?id=UA-113048476-1`,
    internal: `
      window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-113048476-1');
    `,
  },
  fbPixel: `
    !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '2656446227944169'); fbq('track', 'PageView');
  `,
  hotjar: `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2945537,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `,
}

const Analytics = ({ statistics, marketing }) => (
  <Helmet>
    {statistics && (
      <script async src={scripts.googleAnalytics.external}></script>
    )}
    {statistics && <script>{scripts.googleAnalytics.internal}</script>}
    {statistics && <script>{scripts.fbPixel}</script>}
    {marketing && <script>{scripts.hotjar}</script>}
  </Helmet>
)

export default Analytics
