import React from "react"
import styled from "styled-components"
import { Link as LinkTo } from "gatsby"

import { maxD } from "../../../assets/styles/helpers/devices"

import BraveNewLogo from "./../../../assets/images/svg/logo.svg"

import SiteLanguage from "../../../helpers/siteLanguage"

const LogoLink = styled(LinkTo)`
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.7;
  }
`

const BrandLogo = styled(BraveNewLogo)`
  width: 200px;

  @media ${maxD.laptop} {
    width: 150px;
  }

  @media ${maxD.mobileM} {
    width: 140px;
  }

  @media ${maxD.mobileS} {
    width: 100px;
  }

  &.white {
    svg,
    path,
    g {
      fill: #fff;
    }
  }
`

const Brand = ({ linkClassName, logoClassName }) => {
  return (
    <LogoLink to={ SiteLanguage() === "en" ? "/" : "/pl/" } className={linkClassName}>
      <BrandLogo className={logoClassName} />
    </LogoLink>
  )
}

export { Brand }
